import { Inject, Injectable, PLATFORM_ID, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, switchMap, take, tap } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { Router } from "@angular/router";
import {
	getCookie,
	setCookie,
	removeCookie,
} from "@sportyano/core/common/utils/cookies-controller";
import { ChangePasswordCredentials } from "@sportyano/core/models/authentications/change-password.model";
import {
	AutoLoginData,
	LoginCredentials,
	LoginResponse,
	UserData,
} from "@sportyano/core/models/authentications/login-data.model";
import {
	IPhoneVerification,
	IOtpRequest,
	OtpResponse,
	IResendOtp,
} from "@sportyano/core/models/authentications/otp-model";
import {
	IUserRegistrationCycle,
	RegisterResponse,
} from "@sportyano/core/models/authentications/register-data.model";
import { ResetPasswordCredentials } from "@sportyano/core/models/authentications/reset-password.model";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { environment } from "src/environments/environment";
import { IVerifyEmailRequest } from "@sportyano/core/models/authentications/auhtentication.interface";
import { STORAGE } from "@sportyano/core/models/storage/storage.const";
@Injectable({
	providedIn: "root",
})
export class AuthService {
	private forgotPasswordCompleted = false;
	readonly adminAccessStorageKey: string = "adminAccess";
	private readonly apiUrl: string = environment.baseUrl;
	private isAuthenticated: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private loginPopupState: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private quickLoginPopupState: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);
	private readonly _httpClient = inject(HttpClient);
	private readonly _router = inject(Router);
	private userRegisterCycleData: IUserRegistrationCycle =
		{} as IUserRegistrationCycle;

	private _isTemporaryRegistrationEnabled$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	public set setUserData(userData: UserData | null) {
		// Store user data
		localStorage.setItem(STORAGE.userData, JSON.stringify(userData || {}));
	}

	constructor(@Inject(PLATFORM_ID) private platform: object) {
		if (isPlatformBrowser(platform)) {
			// If user authenticated initially, set auth state to true
			if (getCookie(STORAGE.token)) {
				this.setAuthenticationState(true);
			}
			// Registeration cycle data
			const storedRegisterCycleData = sessionStorage.getItem(
				STORAGE.userRegisterCycle
			);
			if (storedRegisterCycleData) {
				this.userRegisterCycleData = JSON.parse(
					storedRegisterCycleData
				);
			}
		}
	}

	public set setToken(accessToken: string) {
		setCookie(STORAGE.token, accessToken || "");
	}

	getRegisterSessionCycle(): IUserRegistrationCycle {
		return this.userRegisterCycleData;
	}

	// Tracking user authentication state
	getAuthenticationState(): Observable<boolean> {
		return this.isAuthenticated.asObservable();
	}

	// Tracking user authentication state without subscribtion
	getAuthenticationStateSync(): boolean {
		return this.isAuthenticated.value;
	}

	// Set login popup open/closed state
	setLoginPopupState(value: boolean) {
		this.loginPopupState.next(value);
	}

	// Set Quick login popup open/closed state
	setQuickLoginPopupState(value: boolean) {
		this.quickLoginPopupState.next(value);
	}

	// Get login popup open/closed state
	getLoginPopupState(): Observable<boolean> {
		return this.loginPopupState.asObservable();
	}

	// Get Quick login popup open/closed state
	getQuickLoginPopupState(): Observable<boolean> {
		return this.quickLoginPopupState.asObservable();
	}

	setAuthenticationState(authValue: boolean): void {
		this.isAuthenticated.next(authValue);
	}

	getTokenCookieKey(): string {
		return STORAGE.token;
	}

	signIn(credentials: LoginCredentials): Observable<LoginResponse> {
		return this._httpClient
			.post<LoginResponse>(this.apiUrl + "auth/login", credentials)
			.pipe(
				switchMap((response) => {
					// Store access token
					setCookie(STORAGE.token, response.token || "");

					// Store user type
					localStorage.setItem(
						STORAGE.userType,
						response.userType || ""
					);

					// Store user data
					this.setUserData = response?.user ?? null;

					// Set user authentication state to true
					this.setAuthenticationState(true);

					// Return a new observable with the response
					return of(response);
				})
			);
	}

	signUp(
		userInformation: FormData,
		userType: string
	): Observable<RegisterResponse> {
		return this._httpClient.post<RegisterResponse>(
			this.apiUrl + `auth/${userType}/register`,
			userInformation
		);
	}

	signOut(): Observable<{ message: string }> {
		return this._httpClient
			.post<{ message: string }>(this.apiUrl + "auth/logout", {})
			.pipe(
				switchMap((res) => {
					if (res) {
						this.removeAuthenticationData();
						this._navigateToLoginPage();
						this.setAuthenticationState(false);
					}
					return of(res);
				})
			);
	}

	removeAuthenticationData() {
		if (isPlatformBrowser(this.platform)) {
			removeCookie(STORAGE.token);
			localStorage.removeItem(STORAGE.userData);
			localStorage.removeItem(STORAGE.userType);
			localStorage.removeItem("adminAccess");
		}
	}
	private _navigateToLoginPage(): void {
		this._router.navigateByUrl("/auth/login");
	}

	setAuthenticationData(response: AutoLoginData) {
		if (isPlatformBrowser(this.platform)) {
			// Store user type
			localStorage.setItem(STORAGE.userType, response.userType || "");

			// Store user data
			localStorage.setItem(
				STORAGE.userData,
				JSON.stringify(response.user || {})
			);

			// Set user authentication state to true
			this.setAuthenticationState(true);
		}
	}

	forgotPassword(phoneNumber: IPhoneVerification) {
		return this._httpClient.post(
			this.apiUrl + "players/reset-password/request",
			phoneNumber
		);
	}

	resetPassword(resetCredentials: ResetPasswordCredentials) {
		return this._httpClient.post(
			this.apiUrl + "players/reset-password",
			resetCredentials
		);
	}

	changePasswordCredentials(changeCredentials: ChangePasswordCredentials) {
		return this._httpClient.post(
			this.apiUrl + "auth/change-password",
			changeCredentials
		);
	}

	otpVerification(userData: IOtpRequest): Observable<OtpResponse> {
		return this._httpClient.post<OtpResponse>(
			this.apiUrl + "phone-numbers/verify",
			userData
		);
	}

	phoneOtpVerification(
		phoneNumber: IPhoneVerification | IOtpRequest
	): Observable<OtpResponse> {
		return this._httpClient.post<OtpResponse>(
			this.apiUrl + "auth/phone-number/otp",
			phoneNumber
		);
	}
	changePassword(phoneNum: IPhoneVerification): Observable<any> {
		return this._httpClient.post(
			`${this.apiUrl}reset-password/request`,
			phoneNum
		);
	}
	changePasswordOTP(otpWithPass: any): Observable<any> {
		return this._httpClient.post(
			`${this.apiUrl}reset-password/`,
			otpWithPass
		);
	}

	public resendOTP(phone: string): Observable<IResendOtp> {
		return this._httpClient.post<IResendOtp>(
			this.apiUrl + "auth/phone-number/resend-otp",
			{
				phone,
			}
		);
	}

	// Use it only inside isPlatformBrowser
	getUserData(): UserData {
		if (isPlatformBrowser(this.platform)) {
			const userData = localStorage.getItem(STORAGE.userData);
			return userData ? JSON.parse(userData) : {} as UserData;
		}

		return {} as UserData;
	}

	// Use it only inside isPlatformBrowser
	getUserType(): UserType {
		if (isPlatformBrowser(this.platform)) {
			const userType = localStorage.getItem(STORAGE.userType);
			return userType as UserType;
		}

		return {} as UserType;
	}

	autoAdminSignIn(accessData: LoginResponse) {
		removeCookie(STORAGE.token);
		localStorage.removeItem(STORAGE.userData);
		localStorage.removeItem(STORAGE.userType);
		localStorage.removeItem(STORAGE.token);
		localStorage.removeItem(this.adminAccessStorageKey);
		localStorage.removeItem("user");

		// Store access token
		setCookie(STORAGE.token, accessData.token || "");

		// Store user type
		localStorage.setItem(STORAGE.userData, accessData.userType || "");

		// Store user data
		localStorage.setItem(
			STORAGE.userData,
			JSON.stringify(accessData.user || {})
		);

		localStorage.setItem(this.adminAccessStorageKey, accessData.type);

		// Set user authentication state to true
		this.setAuthenticationState(true);

		if (accessData.userType === permission.player) {
			this._router.navigate(
				["/main/account/profile-management/player-info"],
				{ queryParams: { activeEditMode: true } }
			);
		}

		if (
			accessData.userType === permission.academy ||
			accessData.userType === permission.playground
		) {
			this._router.navigate([
				"/main/account/branches-management/edit-branche/" +
					accessData.profile_id,
			]);
		}
	}

	get isAdmin(): string {
		if (isPlatformBrowser(this.platform)) {
			return localStorage.getItem(this.adminAccessStorageKey) || "";
		}

		return "";
	}

	public set setTemporaryRegistrationState(state: boolean) {
		this._isTemporaryRegistrationEnabled$.next(state);
	}

	public get getTemporaryRegistrationState$(): Observable<boolean> {
		return this._isTemporaryRegistrationEnabled$.asObservable();
	}

	setForgotPasswordCompleted(status: boolean): void {
		this.forgotPasswordCompleted = status;
	}

	isForgotPasswordCompleted(): boolean {
		return this.forgotPasswordCompleted;
	}

	public emailVerification(email: string): Observable<any> {
		return this._httpClient.post(`${environment.baseUrl}email-addresses`, {
			email: email,
		});
	}

	public resendEmailVerification(email: string): Observable<any> {
		return this._httpClient.post(
			`${environment.baseUrl}email-verification/resend`,
			{
				email: email,
			}
		);
	}

	setRegisterSessionCycle(key: string, value: any): void {
		this.userRegisterCycleData[key] = value;
		this.saveToSession();
	}

	private saveToSession(): void {
		if (isPlatformBrowser(this.platform)) {
			sessionStorage.setItem(
				STORAGE.userRegisterCycle,
				JSON.stringify(this.userRegisterCycleData)
			);
		}
	}

	public verifyUserEmail(payload: IVerifyEmailRequest) {
		return this._httpClient.post(
			`${environment.baseUrl}email-addresses/verify`,
			payload
		);
	}
}
