export enum permission {
	player = "player",
	academy = "academy",
	viwer = "viewer",
	scouting = "scouting",
	playground = "playground",
	admin = "admin",
}


export type UserType =
	| "academy"
	| "playground"
	| "viewer"
	| "scouting"
	| "player"
	| null
